<script lang="ts">
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./HomepageServices.translations.json";
	import Link from "../../../shared/components/Link.svelte";
	import arrowRightLong from "../../../../core/assets/icons/arrow-right-long.svg?raw";
	import Icon from "../../../shared/components/Icon.svelte";
	import integration from "../../../../core/assets/icons/integration.svg?raw";
	import service from "../../../../core/assets/icons/service.svg?raw";
	import bug from "../../../../core/assets/icons/bug.svg?raw";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { servicesCategoriesPathMap, servicesPathMap } from "../../../../core/schema/paths/servicesPathMap.js";
	import { Service } from "../../../../core/schema/Service.js";
	import { serviceCategoryNameMap } from "../../../../core/schema/names/servicesNameMap.js";

	const { TENANT_LOCALE } = getGlobalSettings();
	const translate = getTranslate(translations);
	const localizedSupportLink = `https://support.vertiflex.eu/?lang=${TENANT_LOCALE}`;
</script>

<div class="flex flex-col gap-2 md:grid md:grid-cols-2 xl:grid-cols-4">
	<div class="flex flex-col justify-between py-8 md:max-lg:col-span-2 lg:px-8">
		<h2 class="m-0 p-0 text-left text-3xl">{translate("services")}</h2>
		<Link
			asButton
			href="/{servicesPathMap[TENANT_LOCALE]}"
			variant="primaryTransparent"
			class="text-right text-xs font-semibold uppercase tracking-widest lg:text-left"
			icon={arrowRightLong}
		>
			{translate("allServices")}
		</Link>
	</div>
	<div
		class="border-border flex h-auto flex-col justify-between gap-6 overflow-hidden rounded-lg border-2 bg-gray-300 p-6 duration-300 ease-in-out"
	>
		<div class="flex flex-col gap-3">
			<Icon class="h-[4.25rem] w-fit" icon={integration} />
			<article class="relative z-[1] flex flex-col gap-4 bg-gray-300 lg:h-[17.2rem]">
				<p
					class="text-secondary font-spaceGrotesk group-hover:text-primary row-span-2 m-0 line-clamp-2 p-0 text-[1.5rem] font-bold leading-[1.72rem]"
				>
					{translate("headlineIntegrations")}
				</p>
				<div class="text-2xs line-clamp-7 row-span-4 mt-1 leading-[1.375rem] tracking-wide lg:leading-[1.2rem]">
					{translate("contentIntegrations")}
				</div>
			</article>
		</div>
		<div class="flex flex-wrap items-end justify-start">
			<Link
				aria-label={translate(
					"readMoreAria",
					{ title: serviceCategoryNameMap[Service.Integration][TENANT_LOCALE] },
					true,
				)}
				asButton
				class="font-bold"
				variant="secondary"
				icon={arrowRightLong}
				href="/{servicesPathMap[TENANT_LOCALE]}/{servicesCategoriesPathMap[Service.Integration][TENANT_LOCALE]}"
			>
				{translate("readMore")}
			</Link>
		</div>
	</div>
	<div
		class="border-border flex h-auto flex-col justify-between gap-6 overflow-hidden rounded-lg border-2 bg-gray-300 p-6 duration-300 ease-in-out"
	>
		<div class="flex flex-col gap-3">
			<Icon class="h-[4.25rem] w-fit" icon={service} />
			<article class="relative z-[1] flex flex-col gap-4 bg-gray-300 lg:h-[17.2rem]">
				<p
					class="text-secondary font-spaceGrotesk group-hover:text-primary row-span-2 m-0 line-clamp-2 p-0 text-[1.5rem] font-bold leading-[1.72rem]"
				>
					{translate("headlineService")}
				</p>
				<div class="text-2xs line-clamp-7 row-span-4 mt-1 leading-[1.375rem] tracking-wide lg:leading-[1.2rem]">
					{translate("contentService")}
				</div>
			</article>
		</div>
		<div class="flex flex-wrap items-end justify-start">
			<Link
				asButton
				class="font-bold"
				variant="secondary"
				icon={arrowRightLong}
				href="/{servicesPathMap[TENANT_LOCALE]}/{servicesCategoriesPathMap[Service.Service][TENANT_LOCALE]}"
			>
				{translate("orderService")}
			</Link>
		</div>
	</div>
	<div
		class="border-border flex h-auto flex-col justify-between gap-6 overflow-hidden rounded-lg border-2 bg-gray-300 p-6 duration-300 ease-in-out"
	>
		<div class="flex flex-col gap-3">
			<Icon class="h-[4.25rem] w-fit" icon={bug} />
			<article class="relative z-[1] flex flex-col gap-4 bg-gray-300 lg:h-[17.2rem]">
				<p
					class="text-secondary font-spaceGrotesk group-hover:text-primary row-span-2 m-0 line-clamp-2 p-0 text-[1.5rem] font-bold leading-[1.72rem]"
				>
					{translate("reportProblem")}
				</p>
				<div class="text-2xs row-span-4 mt-1 line-clamp-6 leading-[1.375rem] tracking-wide lg:leading-[1.2rem]">
					{translate("problemContent")}
				</div>
			</article>
		</div>
		<div class="flex flex-wrap items-end justify-start">
			<Link asButton class="font-bold" variant="secondary" icon={arrowRightLong} href={localizedSupportLink}>
				{translate("reportProblem")}
			</Link>
		</div>
	</div>
</div>
